function getRedirectUrl() {
    const params = new URLSearchParams(window.location.search);
    const redirectParam = params.get('redirect_url');
    try {
      const url = new URL(redirectParam);
      return url;
    } catch (error) {
      return null; 
    }
}

export default getRedirectUrl;
