import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import api from "../helpers/api";
import {
  LOGIN_PATH,
  PASSWORD_RESET_EMAIL_PATH,
  REACT_APP_IV_URL,
  REACT_APP_TOKEN_COOKIE_DOMAIN,
  REACT_APP_TOKEN_COOKIE_EXPIRY,
} from "../helpers/constants";
import getRedirectUrl from "../helpers/getRedirectUrl";


export default function Login() {
  const [errorMessage, setErrorMessage] = useState("");
  const [, setCookie] = useCookies(["token"]);

  const validationSchema = yup.object().shape({
    username: yup.string().required("Please enter your username"),
    password: yup.string().required("Please enter your password"),
  });

  const onSuccess = (token, remember) => {
    const redirectUrl =  getRedirectUrl();
    const cookieAttributes = {
      domain: REACT_APP_TOKEN_COOKIE_DOMAIN,
      path: "/"
    };

    if (remember) {
      cookieAttributes.maxAge = REACT_APP_TOKEN_COOKIE_EXPIRY;
    }

    setCookie("token", token, cookieAttributes);
    window.location.assign(redirectUrl||REACT_APP_IV_URL);
  };

  const handleSubmit = async (values) => {
    try {
      const response = await api(
        {
          username: values.username.trim(),
          password: values.password.trim(),
        },
        LOGIN_PATH
      );

      if (response.error) {
        setErrorMessage(response.error);
      } else {
        onSuccess(response.token, values.remember);
      }
    } catch (error) {
      setErrorMessage("There was an error logging in, please try again later.");
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      remember: false,
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      <Typography component="h1" variant="h4" sx={{ mb: 2, mt: 8 }}>
        Welcome
      </Typography>
      <Typography
        component="span"
        variant="body2"
        color="text.secondary"
        sx={{ mb: 4 }}
      >
        Please sign in to continue
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <FormControlLabel
            control={<Checkbox onChange={formik.handleChange} />}
            name="remember"
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            LOG IN
          </Button>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2, width: 1 }}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
      </form>
      <Link component={RouterLink} to={`/auth/${PASSWORD_RESET_EMAIL_PATH}`}>
        Reset Password
      </Link>
    </>
  );
}
